import logo from './logo.svg';
import './App.css';
import FreezableWebcam from './components/webcam/FreezableWebcam';
import ThemedAuthenticator from './components/ThemedAuthenticator';
import React, { useCallback, useRef, useState } from "react";

import VerificationService from './services/VerificationService';
import LoanService from './services/LoanService';

import { SpaceBetween, Container, Button, TextContent,Box, Header, Cards } from '@cloudscape-design/components';

import { Amplify } from 'aws-amplify';
import { useAuthenticator, View, Image } from '@aws-amplify/ui-react';

import {ThemeProvider,
  Theme,
  useTheme, 
  withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';

Amplify.configure(awsExports);

const verificationService = new VerificationService();
const loanService = new LoanService();

const components = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Amplify logo"
          src="https://docs.amplify.aws/assets/logo-dark.svg"
        />
      </View>
      );
    },
  },  
};

function App({}) {


  const [status, setStatus] = useState(null);
  const [resultData, setResultData] = useState(undefined);
  const [responseStatus, setresponseStatus] = useState(undefined);
  const [imageSrc, setImage] = useState(null);
  const [unfreezeButtonText, setunfreezeButtonText] = useState(null);
  const [caputreButtonText, setcaputreButtonText] = useState(null);
  const [resource, setResource] = useState(null);

  const cameraRef = useRef()
  const verificationRef = useRef()

  const onSubmitted = React.useCallback(async (imageSrc) => {
    setStatus("Processing Image.....");
    setResultData("")
    setImage(imageSrc);
    cameraRef.current.unfreeze()
    const result = await verificationService.verify(imageSrc)
    console.log(result.Data)
    setResultData(JSON.parse(result.Data).map((text, i, arr) => ({   text,
    value: arr.length - i})));
    setresponseStatus(result.Code)
    console.log(result.Data)

    if (result.Code < 400) {
      setStatus("Success")
        setcaputreButtonText("Take Another Photo")

    } else {
      setStatus("Request Failed")
 
    }
  }, []);

  const approveLoanGreater = React.useCallback(async () => {
    const result = await loanService.verify(user,resource);
    setresponseStatus(result.Code)
    console.log(result.Data)

  }, []);
  
  const capture = useCallback(async () => {

    const imageSrc = cameraRef.current.capture();
    setcaputreButtonText("Processing...")

    onSubmitted(imageSrc);
  }, [cameraRef]);

  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <div className="App">
          <Container header={
            
                  <Header 
                  variant="h2">
                    Admin Portal
                  </Header>
                }>
                  
                  <h2>Welcome, {user.username}!</h2>
                  <h2>You can approve up to € {user.attributes["custom:maxValuePermitted"]}</h2>

                  <Button onClick={() => approveLoanGreater()}>Approve Loan of 15,000</Button>


              <div className="row">
                <div className="panel"></div><FreezableWebcam ref={cameraRef}></FreezableWebcam>   
                <div className="panel">
                      <img src={imageSrc} /> 
                </div>
              </div>
              <div className="centralrow">
            <Button onClick={() => capture()}>{caputreButtonText || "Capture"}</Button>
            <Button onClick={() => signOut()}>Sign Out</Button>
            </div>
              <div className="centralrow">
              <TextContent>{status}</TextContent>
          
            </div>
          </Container>
    </div>
  );
}


export default (App);
