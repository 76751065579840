import superagent from "superagent";

const api_url = "https://1f8dip3m10.execute-api.eu-central-1.amazonaws.com/live/test";

class VerificationService {
  constructor() {}

  async sleep(duration = 1000) {
    return await new Promise((resolve) =>
      setTimeout(() => resolve(), duration)
    );
  }

  async verify(imageSrc) {
    const base64 = imageSrc.replace(/^data:image\/\w+;base64,/, "");
    const buf = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));

    try {
      const result = await superagent
        .post(api_url)
        .set('accept','application/json')
        .set('content-type','application/json')
        .ok(() => true)
        .send( base64 );

      console.log(result);
      return {
        Data: result.text,
        Code: result.statusCode,
      };
    } catch (err) {
      console.error(err);
    }

  }
}

export default VerificationService;
